/* eslint-disable operator-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PropTypes from 'prop-types';

// Packages

// UI lib components

// UI local components
import TextInput from '../../../shared/Uikit/TextInput';
import Textarea from '../../../shared/Uikit/Textarea';
import Spinner from '../../../shared/Uikit/Spinner';

// styles
import './index.scss';

// assets
import handIcon from '../../../images/contactPage/hand.svg';
// api
import { CONTACT_ENDPOINT } from '../../../shared/Helpers/api';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function ContactForm({ setStep }) {
  /* ********************************** HOOKS ********************************* */
  const [formData, setFormData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  // Localization
  const { t } = useTranslation();
  const { title, subtitle, formElements, buttonText } = t('form', {
    returnObjects: true,
  });

  const INVALID = 'INVALID';
  const TEL_TYPE = 'tel';
  const TEXT_TYPE = 'text';
  const EMAIL_TYPE = 'email';

  const handleChange = (e) => {
    if (fieldErrors[e.target.name]) {
      setFieldErrors({ ...fieldErrors, [e.target.name]: undefined });
    }
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setResponse();
  };

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let isValid = true;
      const validationErrors = {};
      formElements.forEach(({ name, type, required }) => {
        if (!required) return;
        if (type === TEL_TYPE) {
          const regex =
            /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
          if (!formData[name]?.match(regex)) {
            isValid = false;
            validationErrors[name] = INVALID;
          }
        } else if (type === EMAIL_TYPE) {
          const regex =
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
          if (!formData[name]?.match(regex)) {
            isValid = false;
            validationErrors[name] = INVALID;
          }
        } else if (type === TEXT_TYPE) {
          if (!formData[name]?.trim()?.length) {
            isValid = false;
            validationErrors[name] = INVALID;
          }
        }
      });
      setFieldErrors(validationErrors);
      if (isValid) {
        await axios.post(CONTACT_ENDPOINT, {
          data: { ...formData },
        });
        setResponse('SUCCESS');
        setFormData({});
        setStep((step) => step + 1);
      } else {
        setFieldErrors(validationErrors);
      }
    } catch (error) {
      setResponse('ERROR');
      setLoading(false);
    }
    setLoading(false);
  };

  /* ******************************** RENDER HELPERS ******************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <div className="vw-60 vw-70-med bg-white f-center h-100 contact-form flex-column pt-30-m">
      <div className="flex flex-column py-70 py-70-med p-0-m">
        <p className="f60 f48-med f40-m bold text-darkBlue">
          {title?.map((v, index) => (
            <span className={`${index % 2 === 0 ? 'regular' : ''}`}>
              {index > 0 ? ` ${v}` : v}
            </span>
          ))}
          <img src={handIcon} alt="" className="ms-10 vertical-align-mid" />
        </p>
        <span className="bold f24 text-darkBlue my-60 my-40-med m-0-m mt-35-m">
          {subtitle}
        </span>
        <form>
          <Row>
            {formElements.map(({ name, type, placeholder, id, span }) => {
              if (type === 'textarea') {
                return (
                  <Col lg={span} key={name}>
                    <div className={`${span === 12 ? 'mt-30' : 'mt-30-m'}`}>
                      <Textarea
                        name={name}
                        id={id}
                        type={type}
                        placeholder={placeholder}
                        value={formData[name] ?? ''}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                );
              }
              return (
                <Col lg={span} key={name}>
                  <div className={`${span === 12 ? 'mt-30' : 'mt-30-m'}`}>
                    <TextInput
                      name={name}
                      id={id}
                      type={type}
                      placeholder={placeholder}
                      value={formData[name] ?? ''}
                      onChange={handleChange}
                      fieldError={fieldErrors[name] === INVALID}
                    />
                  </div>
                </Col>
              );
            })}
            <Col lg={12}>
              <button
                type="button"
                className="f-center bg-dark border-none outline-none p-24 bg-darkBlue text-white f16 br8 bold cursor-pointer w-100 mt-30"
                onClick={handleSubmit}
              >
                {loading ? <Spinner /> : buttonText}
              </button>
            </Col>
          </Row>
        </form>
      </div>
      {response && (
        <div
          className={`${response === 'ERROR' ? 'error-msg' : ''} ${
            response === 'SUCCESS' ? 'success-msg' : ''
          } align-c`}
        >
          {response === 'SUCCESS' ? '' : ''}
          {response === 'ERROR' ? "un message d'erreur s'est produit" : ''}
        </div>
      )}
    </div>
  );
}

ContactForm.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default ContactForm;
